<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>社区管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/postManage' }">帖子管理</el-breadcrumb-item>
      <el-breadcrumb-item>帖子详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="text-align: left;width: 100%;">
      <el-button size="small" @click="shenhe">{{ auditbtn }}</el-button>
      <el-button size="small" style="margin-left:20px" @click="setjh">{{ btntext }}</el-button>
      <el-button size="small" style="margin-left:20px" @click="del">删除</el-button>
      <el-button size="small" style="margin-left:20px" @click="kefuback('tz', '')">回复</el-button>
    </div>
    <div class="main">
      <h3 style="font-weight: normal;">{{ list.title }}</h3>
      <div class="author">
        <img :src="list.headimg" class="headimg" alt="" />
        <p style="padding-top: 8px;">{{ list.author_name }}</p>
        <p>{{ list.tel }}</p>
      </div>
      <div class="content">
        <p>{{ list.content }}</p>
        <video
          v-if="vediosrcshow"
          :src="vediosrc"
          controls="controls"
          style="height: 400px;width: 600px;"
        ></video>
        <img :src="item" alt="" v-for="(item, index) in imglist" :key="index" />
      </div>
    </div>
    <el-divider></el-divider>
    <div class="talk">
      <h4>评论列表：</h4>
      <div style="text-align: center;" v-if="talklist == ''">暂时没有评论</div>
      <div v-else class="plmaink">
        <div v-for="(item, index) in talklist" :key="index">
          <div class="plline">
            <div class="plleft">
              <img :src="item.headimg" alt="" class="plimg" />
            </div>
            <div class="center_self">
              <div class="center_user_name">
                <p>{{ item.author_name }}</p>
                <p v-if="item.author_id != '8'">{{ item.tel }}</p>
              </div>
              <div class="minheight">{{ item.comment }}</div>
              <div class="createtime">
                <span>{{ item.create_time }}</span>
                <el-link icon="el-icon-edit" @click="kefuback('pl', item)" type="primary"
                  >回复</el-link
                >
                <el-link icon="el-icon-delete" @click="deltalk(item)" type="danger">删除</el-link>
              </div>
            </div>
          </div>
          <div
            v-for="(item1, index1) in item.reply"
            :key="index1"
            class="plline bordertop"
            style="box-sizing:border-box;"
          >
            <div class="plleft">
              <img :src="filterimg(item1.headimg)" alt="" class="plimg" />
            </div>
            <div class="center_self">
              <div class="center_user_name">
                <p>{{ item1.author_name }}</p>
                <p v-if="item1.author_id != '8'">{{ item1.tel }}</p>
              </div>
              <div class="minheight">
                <span
                  style="color:orange;margin-right:8px;"
                  v-if="item1.to_name != '' && item1.to_name != null && item1.to_name != undefined"
                  >@&nbsp;{{ item1.to_name }}</span
                >{{ item1.comment }}
              </div>
              <div class="createtime">
                <span>{{ item1.create_time }}</span>
                <el-link icon="el-icon-edit" @click="kefuback('pl', item1)" type="primary"
                  >回复</el-link
                >
                <el-link icon="el-icon-delete" @click="deltalk(item1)" type="danger">删除</el-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="pageSizes"
          :page-size="PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          :hide-on-single-page="value"
        >
        </el-pagination>
      </p>
      <el-dialog title="客服回复" :visible.sync="dialogVisible" width="30%">
        <el-form label-width="80px">
          <el-form-item label="回复内容">
            <el-input type="textarea" v-model="comment"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitkefutalk">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
var objExp = new RegExp(Expression);
export default {
  inject: ["reload"],
  data() {
    return {
      id: "",
      list: {},
      talk: "",
      di: "",
      audit: "",
      btntext: "设为精华",
      auditbtn: "审核",
      talklist: "",
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      imglist: [],
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      vediosrc: "",
      vediosrcshow: false,
      dialogVisible: false,
      comment: "",
      huifutalk: "",
      huifuitem: "",
      talklistreplay: [],
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getpostdetail();
    this.gettalk(1);
  },
  methods: {
    filterimg(val) {
      if (objExp.test(val)) {
        val = val.headimg;
      } else {
        val = this.male;
      }
      return val;
    },
    getpostdetail() {
      this.axios
        .get("/bbs/get_gu_bbs_post_by_id?id=" + this.id + "&audit=" + 0)
        .then((res) => {
          if (res.data.code == 0) {
            var _str = res.data.result.headimg;
            this.list = res.data.result;
            if (objExp.test(_str)) {
              this.list.headimg = res.data.result.headimg;
            } else if (res.data.result.headimg == null) {
              this.list.headimg = this.male;
            } else {
              this.list.headimg = this.male;
            }
            this.di = res.data.result.di;
            this.audit = res.data.result.is_audit;
            if (this.di != 0) {
              this.btntext = "取消精华";
            } else {
              this.btntext = "设为精华";
            }
            if (this.audit != 0) {
              this.auditbtn = "取消审核";
            } else {
              this.auditbtn = "审核";
            }
            if (
              res.data.result.imgs != "" &&
              res.data.result.imgs != null &&
              res.data.result.imgs != undefined
            ) {
              let list = JSON.parse(res.data.result.imgs);
              this.imglist = list;
            }
            if (
              res.data.result.video != "" &&
              res.data.result.video != null &&
              res.data.result.video != undefined
            ) {
              this.vediosrcshow = true;
              this.vediosrc = res.data.result.video;
            }
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gettalk(page) {
      ///bbs/get_gu_bbs_comment
      this.axios
        .get(
          "/bbs/get_gu_bbs_comment_by_replygroup?post_id=" +
            this.id +
            "&page=" +
            page +
            "&size=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.talklist = res.data.result;
            var list = [];
            var arr = res.data.result;
            for (var i in this.talklist) {
              if (objExp.test(this.talklist[i].headimg)) {
                this.talklist[i].headimg = this.talklist[i].headimg;
              } else {
                this.talklist[i].headimg = this.male;
              }
            }
            this.totalCount = res.data.count;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.gettalk(currentPage);
    },
    del() {
      this.$confirm("此操作将永久删除该帖子, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/bbs/delete_gu_bbs_post", this.qs.stringify({ id: this.id }))
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.$router.push({ path: "/postManage" });
              } else {
                this.$message(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    shenhe() {
      //审核
      if (this.audit == 0) {
        this.audit = 1;
      } else {
        this.audit = 0;
      }
      this.setsome("/bbs/update_gu_bbs_post_audit", { audit: this.audit, id: this.id }, "audit");
    },
    setjh() {
      //设置精华
      if (this.di == 0) {
        this.di = 1;
      } else {
        this.di = 0;
      }
      this.setsome("/bbs/update_gu_bbs_post_di", { di: this.di, id: this.id }, "di");
    },
    setsome(url, form, caozuo) {
      this.axios
        .post(url, this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            if (caozuo == "di") {
              if (this.di != 0) {
                this.btntext = "取消精华";
                this.$message({
                  type: "success",
                  message: "设置成功!",
                });
              } else {
                this.btntext = "设为精华";
                this.$message({
                  type: "success",
                  message: "取消成功!",
                });
              }
            } else {
              if (this.audit != 0) {
                this.auditbtn = "取消审核";
                this.$message({
                  type: "success",
                  message: "审核成功!",
                });
              } else {
                this.auditbtn = "审核";
                this.$message({
                  type: "success",
                  message: "取消审核成功!",
                });
              }
            }
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deltalk(item) {
      this.$confirm("此操作将删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/bbs/delete_gu_bbs_comment",
              this.qs.stringify({ id: item.id, post_id: this.id })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.reload();
              } else {
                this.$message(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    kefuback(type, item) {
      this.dialogVisible = true;
      this.huifutalk = type;
      this.huifuitem = item;
    },
    submitkefutalk() {
      let form = {
        comment: this.comment, //内容
        author_id: sessionStorage.getItem("cust_id"), //用户的id
        author_name: sessionStorage.getItem("name"), //姓名
        headimg: "", //头像
        //reply_id: '',//评论id，没有评论id可以传空
        post_id: this.id, //帖子id
        to_name: this.huifuitem.author_name,
      };
      if (this.huifutalk == "tz") {
        form.reply_id = "";
      } else {
        form.reply_id = this.huifuitem.id;
      }
      console.log(form);
      this.axios
        .post("/bbs/insert_gu_bbs_comment", this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "回复成功",
            });
            this.reload();
          } else {
            this.$message({
              type: "info",
              message: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.main {
  text-align: left;
}

.talk {
  text-align: left;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 2px 0;
}

.deltalk {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
  line-height: 70px;
}

.talk .paging {
  height: 40px;
  padding-top: 20px;
  text-align: center;
}

.author {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}

.headimg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}

.content {
  width: 100%;
  overflow: hidden;
}

.content p {
  line-height: 20px;
  font-size: 16px;
}

.content img {
  width: 200px;
  overflow: hidden;
  display: block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.borderright {
  border-right: 1px solid #dcdfe6;
  width: 190px;
}

.plleft {
  text-align: left;
  font-size: 14px;
  padding-top: 20px;
  padding-left: 20px;
  float: left;
  min-height: 80px;
  box-sizing: border-box;
}

.talk .plleft p {
  margin-top: 6px;
  margin-bottom: 0;
}

.plimg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 8px;
}

.talk .el-row {
  border-bottom: 1px solid #dcdfe6;
}

.talkone {
  border-top: 1px solid #dcdfe6;
}

.borderleft {
  line-height: 70px;
}

.createtime {
  font-size: 12px;
  margin: 0;
  color: #bbbbbb;
  margin-top: 10px;
}

.left_self {
  width: 190px;
}

.right_self {
  width: 120px;
  margin-left: 10px;
}

.left_self {
  float: left;
}

.right_self {
  float: right;
}

.center_self {
  margin: 0 0 0 80px;
  padding: 20px 10px 10px 10px;
  font-size: 14px;
  overflow: hidden;
}
.center_self div {
  float: left;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}
.author p {
  margin: 0;
  line-height: 24px;
}

.plline {
  min-height: 80px;
}
.bordertop {
  margin-left: 60px;
}
.bordertop .plimg {
  width: 30px;
  height: 30px;
}
.bordertop:last-child {
  border-bottom: 0;
}
.bordertop .center_self {
  margin-left: 60px;
}
.delbtn {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.createtime .el-link {
  font-size: 12px;
  margin-left: 20px;
}
.plmaink {
  overflow: hidden;
  background-color: #f7f8f7;
  border-radius: 10px;
}
.center_user_name {
  margin-bottom: 10px;
}
.center_user_name p {
  float: left;
  margin: 0;
  margin-right: 10px;
  font-size: 12px;
}
.center_self .createtime {
  font-size: 12px;
}
</style>
